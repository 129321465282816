/***************************************************
  Imports
***************************************************/

import React from "react"

import List from "../../components/List"
import PageTypeE from "../../templates/PageTypeE"

import TitleImg from "../../images/D365-sales-and-marketing.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Sales and Marketing"
      descripton="Give people in your organization access to accurate, up-to-date information and the tools to efficiently manage contacts, opportunities, and campaigns-while."
      linkSubPageGroupID="D365Group"
      titleImg={TitleImg}
      infoList={[
        {
          title: "Run your sales and marketing effciently",
          spread: true,
          text: (
            <p>
              Microsoft Dynamics Business Central can give people in your
              organization access to accurate, up-to-date information and the
              tools to efficiently manage contacts, opportunities, and
              campaigns-while building customer relationships that help drive
              sales and encourage loyalty. Everyone in your organization can get
              the information they need to interact with customers in a
              profitable way-and make smart decisions based on accurate
              information about every contact. You can also automate routine
              sales, marketing, and customer service tasks to make your work
              environment simpler and more efficient.
              <br />
              <br />
              Put customer information at the fingertips of managers and
              employees to help your people build better customer relations.
              <List
                className="dark"
                items={[
                  {
                    text: `Manage contact information`,
                  },
                  {
                    text: `Organize sales campaigns`,
                  },
                  {
                    text: `Identify sales opportunities`,
                  },
                  {
                    text: `Automate sales tasks`,
                  },
                  {
                    text: `Set up automatic reminders`,
                  },
                  {
                    text: `Document approval`,
                  },
                ]}
              />
            </p>
          ),
        },
      ]}
    />
  )
}
